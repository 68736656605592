import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/assets/config/app-settings';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient, private settings: AppSettings) { }

  private upload(customerId: string, file: Blob, type: string, filepath = '', isAudio: boolean): Observable<UploadResponse> {
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    const formData = new FormData();
    formData.append('file', file, (file as any).name);
    formData.append('filepath', filepath);
    const endpoint = isAudio ? 'file' : 'blob';
    return this.http.post<UploadResponse>(`${this.settings.apiBaseUrl}/api/${endpoint}/upload/${customerId}/${type}`, formData, { headers });
  }

  private delete(filename: string, isAudio: boolean): Observable<any> {
    const options = { body: { filename } };
    const endpoint = isAudio ? 'file' : 'blob';
    return this.http.delete(`${this.settings.apiBaseUrl}/api/${endpoint}/delete`, options);
  }

  uploadAudio(customerId: string, file: Blob, type: string, filepath = ''): Observable<UploadResponse> {
    return this.upload(customerId, file, type, filepath, true);
  }

  deleteAudio(filename: string): Observable<any> {
    return this.delete(filename, true);
  }

  uploadFile(customerId: string, file: Blob, type: string, filepath = ''): Observable<UploadResponse> {
    return this.upload(customerId, file, type, filepath, false);
  }

  deleteFile(filename: string): Observable<any> {
    return this.delete(filename, false);
  }
}

export interface UploadResponse {
  code?: number;
  error?: boolean;
  message?: string;
  results?: {
    link?: string;
  }  
}
